<template>
  <PageContainer
    :title="$translations['forgot-password'].title"
    :back="actions.back">
    <template v-slot:content>
      <div class="page-segment">
        <div class="page-segment-content">
          <SkeletonDefault v-if="isLoading" />

          <template v-else>
            <div class="font-bold">{{ $translations['forgot-password'].verification.title }}</div>
            <div class="body margin-top">{{ $translations['forgot-password'].verification.subtitle }}</div>
            <div class="flex margin-y-xl justify-center">
              <CodeInput
                class="input"
                :loading="false"
                @change="onChange"
                @complete="onComplete" />
            </div>
            <!-- TODO: se creo una tarea para atacar esto como un nuevo feature -->
            <!-- <div class="text-sm text-black-50 w-full text-center mb-4">
              {{ $translations['forgot-password'].verification.retry }}
            </div> -->
            <Button
              :text="$translations['forgot-password'].verification.button.verify"
              :disable="!isValid"
              :loading="loading"
              @clicked="verify()" />
          </template>
        </div>
      </div>
    </template>t
  </PageContainer>
</template>

<script>
import { PageContainer, Button } from '@seliaco/red-panda'
import SkeletonDefault from '@/components/skeletons/SkeletonDefault'
import CodeInput from 'vue-verification-code-input'

export default {
  components: {
    PageContainer,
    SkeletonDefault,
    CodeInput,
    Button
  },
  computed: {
    isValid () {
      return this.code.length === 6
    }
  },
  data () {
    return {
      loading: false,
      isLoading: true,
      code: '',
      actions: {
        back: {
          callback: () => {
            this.$router.go(-1)
          }
        }
      }
    }
  },
  methods: {
    onChange (value) {
      this.code = value
    },
    onComplete (value) {
      this.code = value
    },
    verify () {
      this.loading = true
      this.$auth.onValidateCode(
        this.code
      ).then(() => {
        this.$router.push({
          name: 'ChangePassword',
          query: {
            code: this.code
          }
        })
      }).catch(() => {
        this.$toast({
          severity: 'error',
          text: this.$translations.error['invalid-code']
        })
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    setTimeout(() => {
      this.isLoading = false
    }, 100)
  }
}
</script>

<style lang="sass">
.react-code-input
  @apply space-x-1
  input
    width: 48px !important
    display: inline
</style>
