<template>
  <div>
    <PasswordField
      v-model="newPassword"
      class="margin-top"
      regex="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])"
      :label="$translations.form['new-password']"
      :enabled-regex="true"
      @onError="verifyPassword($event)"
      @change="changeNewPassword"
    />

    <PasswordValidation
      class="margin-top-xs margin-bottom"
      :password="newPassword"
      :validationRegex="validationPassword"
    />
    <PasswordField
      class="margin-y"
      v-model="rePassword"
      :label="$translations.form['repeat-new-password']"
      :error="!isSamePassword"
      @change="validRePassword"
    />
  </div>
</template>

<script>
import { PasswordField } from '@seliaco/sea-otter'
import PasswordValidation from './PasswordValidation.vue'

export default {
  name: 'FormChangePassword',
  components: {
    PasswordField,
    PasswordValidation
  },
  data () {
    return {
      newPassword: '',
      rePassword: '',
      validationPassword: false,
      isSamePassword: true
    }
  },
  methods: {
    changeNewPassword () {
      if (this.rePassword) {
        this.validRePassword()
      }

      this.$emit('changedNewPassword', this.newPassword)
    },
    validRePassword () {
      this.isSamePassword = this.newPassword === this.rePassword
      this.$emit(
        'valid',
        this.validationPassword &&
          this.isSamePassword &&
          this.newPassword.length >= 8
      )
    },
    verifyPassword (event) {
      this.validationPassword = event
    }
  }
}
</script>
