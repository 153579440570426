<template>
  <PageContainer
    :title="$translations.general['change-password']"
    :back="actions.back"
  >
    <template v-slot:content>
      <div class="page-segment">
        <div class="page-segment-content">
          <PasswordField
            v-model="password"
            :label="$translations.form['current-password']"
          />
          <div
            class="text-right cursor-pointer content-title margin-top"
            @click="goToForgotPassword"
            v-text="translations.link"
          />
          <FormChangePassword
            class="padding-top"
            @valid="validation = $event"
            @changedNewPassword="newPassword = $event"
          />
          <Button
            :text="translations.verification.button.update"
            :disable="!isValid"
            :loading="loadingButton"
            @clicked="updatePassword"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer, Button } from '@seliaco/red-panda'
import FormChangePassword from './components/FormChangePassword'
import { PasswordField } from '@seliaco/sea-otter'

export default {
  components: {
    PageContainer,
    Button,
    FormChangePassword,
    PasswordField
  },
  data () {
    const translations = this.$translations['forgot-password']

    return {
      translations,
      loadingButton: false,
      validation: false,
      password: '',
      newPassword: '',
      actions: {
        back: {
          callback: () => {
            this.$router.replace({ name: 'Profile' })
          }
        }
      }
    }
  },
  methods: {
    updatePassword () {
      this.loading = true
      this.$auth
        .onUpdatePassword(this.password, this.newPassword)
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.auth['change-password-successfully']
          })
          this.$router.replace({ name: 'Profile' })
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.error['change-password']
          })
        })
        .finally(() => {
          this.loadingButton = false
        })
    },
    goToForgotPassword () {
      this.$router.push({ name: 'ForgotPassword' })
    }
  },
  computed: {
    isValid () {
      return Boolean(this.password && this.newPassword && this.validation)
    }
  }
}
</script>
